import { KML } from 'ol/format.js';
import { GeoJSON } from 'ol/format.js';
import { Map, View } from 'ol';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { Vector } from 'ol/source';
import { fromLonLat } from 'ol/proj';
import { XYZ } from 'ol/source.js';
import { Feature } from 'ol';
import { Overlay } from 'ol';
import { Style } from 'ol/style';
import { Icon } from 'ol/style';
import { Cluster } from 'ol/source';

const coordinates = fromLonLat([29.199463, 15.862807]);

const key = 'HZRLl1xB4MTKrSltCQoR';
const attributions =
  '<a href="https://willshir.com" target="_blank">&copy; Willshir</a> ' +
  '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> ' +
  '<a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>';

const raster = new TileLayer({
  source: new XYZ({
    attributions: attributions,
    url: 'https://api.maptiler.com/maps/hybrid/{z}/{x}/{y}.jpg?key=' + key,
    maxZoom: 20,
  }),
});

const vector = new VectorLayer({
  source: new Vector({
    url: 'https://willshir.com/SudanOverlays.kml',
    format: new KML(),
  }),
});

const map = new Map({
  layers: [raster, vector],
  target: document.getElementById('map'),
  view: new View({
    center: coordinates,
    projection: 'EPSG:3857',
    zoom: 6,
  }),
});

// create a vector source with the GeoJSON file
var vectorSource = new Vector({
  format: new GeoJSON(),
  url: 'https://willshir.com/get-events-all.php',
});



function createMarkerStyle(feature) {
  var eventclass = feature.get('eventclass');
  var iconSrc;
  var className;

  // Assign different icons and class names based on the "eventclass" value
  if (eventclass === 'MILITARY') {
    iconSrc = 'https://willshir.com/mildot.png';
    className = 'military-marker-icon';
  } else if (eventclass === 'ALERT') {
    iconSrc = 'https://willshir.com/alertdot.png';
    className = 'alert-marker-icon';
  } else if (eventclass === 'CRIME') {
    iconSrc = 'https://willshir.com/crimedot.png';
    className = 'crime-marker-icon';
  } else if (eventclass === 'DIPLOMATIC') {
    iconSrc = 'https://willshir.com/dipdot.png';
    className = 'diplomatic-marker-icon';
  } else if (eventclass === 'POLITICS') {
    iconSrc = 'https://willshir.com/poldot.png';
    className = 'politics-marker-icon';
  } else if (eventclass === 'TERRORISM') {
    iconSrc = 'https://willshir.com/terdot.png';
    className = 'terrorism-marker-icon';
  } else {
    iconSrc = 'https://willshir.com/othdot.png';
    className = 'other-marker-icon';
  }

  return new Style({
    image: new Icon({
      src: iconSrc,
      className: className,
    }),
  });
}

// create a vector layer with the vector source and marker style
var vectorLayer = new VectorLayer({
  source: vectorSource,
  style: function (feature) {
    var eventclass = feature.get('eventclass');
    return createMarkerStyle(feature);
  },
});

// add the vector layer to the map
map.addLayer(vectorLayer);


// create an overlay for the popup
var popupOverlay = new Overlay({
  element: document.getElementById('popup'),
  positioning: 'bottom-center',
  stopEvent: false, // Allow click events to propagate to the map
});

// Add the overlay to the map
map.addOverlay(popupOverlay);

// Event listener for clicking on features
map.on('click', function (evt) {
  var features = [];
  map.forEachFeatureAtPixel(evt.pixel, function (feature) {
    features.push(feature);
  });

  if (features.length > 0) {
    var info = [];
    features.forEach(function (feature) {
      info.push(feature.get('name'));
    });

    var content = info.join(', ') || '(unknown)';
    popupOverlay.getElement().innerHTML = content;
    popupOverlay.setPosition(evt.coordinate);
    popupOverlay.setPositioning('bottom-center');
    popupOverlay.setOffset([0, -10]);
    popupOverlay.getElement().style.display = 'block';
  } else {
    popupOverlay.getElement().style.display = 'none';
  }
});

// Create an object to store the checkbox elements
const checkboxes = {
  alert: document.getElementById('alertCheckbox'),
  crime: document.getElementById('crimeCheckbox'),
  diplomatic: document.getElementById('diplomaticCheckbox'),
  military: document.getElementById('militaryCheckbox'),
  other: document.getElementById('otherCheckbox'),
  politics: document.getElementById('politicsCheckbox'),
  terrorism: document.getElementById('terrorismCheckbox'),
};

// Function to update the visibility of markers based on checkbox states
function updateMarkerVisibility() {
  const visibleEventClasses = [];

  // Check the state of each checkbox and add the corresponding eventclass to the visibleEventClasses array
  if (checkboxes.alert.checked) {
    visibleEventClasses.push('ALERT');
  }
  if (checkboxes.crime.checked) {
    visibleEventClasses.push('CRIME');
  }
  if (checkboxes.diplomatic.checked) {
    visibleEventClasses.push('DIPLOMATIC');
  }
  if (checkboxes.military.checked) {
    visibleEventClasses.push('MILITARY');
  }
  if (checkboxes.other.checked) {
    visibleEventClasses.push('OTHER');
  }
  if (checkboxes.politics.checked) {
    visibleEventClasses.push('POLITICS');
  }
  if (checkboxes.terrorism.checked) {
    visibleEventClasses.push('TERRORISM');
  }

  // Add conditions for other checkbox elements

  // Filter the features in the vector source based on the visibleEventClasses
  vectorSource.forEachFeature(function (feature) {
    const eventclass = feature.get('eventclass');
    const isVisible = visibleEventClasses.includes(eventclass);
    feature.setStyle(isVisible ? createMarkerStyle(feature) : null);
  });
}

// Event listeners for checkbox changes
checkboxes.alert.addEventListener('change', updateMarkerVisibility);
checkboxes.crime.addEventListener('change', updateMarkerVisibility);
checkboxes.diplomatic.addEventListener('change', updateMarkerVisibility);
checkboxes.military.addEventListener('change', updateMarkerVisibility);
checkboxes.other.addEventListener('change', updateMarkerVisibility);
checkboxes.politics.addEventListener('change', updateMarkerVisibility);
checkboxes.terrorism.addEventListener('change', updateMarkerVisibility);
